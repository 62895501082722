import { phpInstance } from "@/plugins/axios";

const firstAdmissionService = {};

firstAdmissionService.getImageAndWordToConfirmation = () => {
  return phpInstance.post(`/user/flow/toSelectImageAndword`);
};

firstAdmissionService.postImageAndWordToConfirmation = data => {
  const obj = {
    ...data
  };
  return phpInstance.post(`/user/flow/toCreateID`, obj);
};

firstAdmissionService.firstLogin = obj => {
  return phpInstance.post(`/user/flow/toCreateID`, obj);
};

firstAdmissionService.recoverAtId = id => {
  const obj = {
    id
  };
  return phpInstance.post(`/user/flow/validateId`, obj);
};

firstAdmissionService.postEmailToValidate = email => {
  return phpInstance.get(`/user/flow/validateEmail/${email}`);
};

firstAdmissionService.postCourseToValidate = course => {
  return phpInstance.get(`/user/flow/validarCourse/${course}`);
};

firstAdmissionService.getImageAndWordToValidate = () => {
  return phpInstance.post(`/user/flow/toImageAndWordCorrect`);
};

firstAdmissionService.postImageAndWordValidation = data => {
  const id = sessionStorage.getItem("idUser");
  const obj = {
    ...data,
    id
  };
  return phpInstance.post(`/user/flow/validateImageAndWord`, obj);
};

export default firstAdmissionService;
