<template src="./RecoveryAtIdB.html">
</template>

<script>
import firstAdmissionService from '@/services/FirstAdmission';
import ArrobaIdIcon from '@/components/Icons/ArrobaIdIcon';

export default {
  name: 'recovery-at-id-b',
  components: { ArrobaIdIcon },
  data () {
    return {
      atId: null
    }
  },
  methods: {
    postAtId() {
      if (this.atId === null) {
        this.$router.push({path: '/recuperatidc'})
      } else {
        firstAdmissionService.recoverAtId(this.atId)
          .then(response => {
            if (response.arrobaId === false) {
              this.$router.push({path: '/recuperatiderror'})
            } else if (response.arrobaId !== false) {
              this.$router.push({path: '/recuperatide'})
            }
          }).catch((e)=>{
            console.error('error', e);
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped src="./RecoveryAtIdB.scss">
</style>
